<template>
  <div class="pushs-list">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <PageHeader
      :has-actions="true"
      :has-back="true"
      :title="$t('pushes.title')"
      @back="goBack"
    >
      <md-button
        class="md-primary header-action header-action-icon"
        @click="onAdd"
      >
        <MeepIconActionAdd class="app-icon-stroke-inverted" />
      </md-button>
    </PageHeader>
    <div class="pushs-list-content page-layout">
      <!-- La liste des entreprises -->
      <LoadingCard v-if="isLoading" />
      <template v-else>
        <sortable-list
          v-if="pushs.length"
          :items="pushs"
          :parameters="listParameters"
          class="page-list"
          default-sort="sent_at"
          defaultSortOrder="desc"
        />
        <NoItem
          v-else
          :buttonText="$t('pushes-list.no-items.buttonText')"
          :isCard="true"
          :onClick="onAdd"
          :text="$t('pushes-list.no-items.text')"
          :title="$t('pushes.title')"
        >
          <MeepIconNotificationMobile class="app-icon" />
        </NoItem>
      </template>
    </div>
  </div>
</template>

<script>
import pushsModel from "../../model/pushs";
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconNotificationMobile from "@/components/icons/MeepIconNotificationMobile.vue";
import NoItem from "@/components/NoItem.vue";
import LoadingCard from "@/components/LoadingCard";

export default {
  components: {
    PageHeader,
    Banner,
    MeepIconChat,
    MeepIconActionAdd,
    MeepIconNotificationMobile,
    NoItem,
    LoadingCard,
  },
  data() {
    return {
      isDebug: false,
      pushs: [],
      listParameters: [
        {
          name: "Message",
          key: "content",
        },
        {
          name: "Date d'envoi",
          key: "sent_at",
          format: this.$$filters.formatTimestamp,
          width: "15%",
        },
      ],
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    try {
      this.pushs = await pushsModel.getAll();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.$toasted.global.AppError({
        message: error.msg,
      });
      this.isLoading = true;
    }
  },
  methods: {
    goBack() {
      window.history.back();
    },

    onAdd() {
      this.$router.push("/dashboard/communicate/pushs/create");
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";

#app {
  .pushs-list {
    .debug-log {
      max-height: 200px;
      overflow-y: scroll;
    }

    .page-header {
      .md-theme-default {
        .md-icon.md-primary {
          width: 24px;
          height: 24px;
          border-radius: 50%;

          @include for-lg {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .setting-card {
      padding: 65px 16px;
      width: 100%;
      height: 520px;

      .md-button {
        padding: 10px 20px;
        width: unset;
      }

      &-text {
        color: var(--text-inverted);
      }
    }

    &-content {
      &.page-layout {
        height: 100%;
      }
    }
  }
}
</style>
