import fetch from '../services/fetch';
import store from '../store';

export default {
   getAll,
   send
};

function getAll() {
   return fetch('api/pushs', {
      method: 'get',
      headers: {'Authorization': store.state.token},
   });
}

function send(form) {
   return fetch('api/pushs/send', {
      method: 'post',
      headers: {
         'Authorization': store.state.token,
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
   });
}
